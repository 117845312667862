import React, { useState } from 'react';

const VideoContext = React.createContext();

function VideoContextProvider({ children }) {
  // to handle the playiing video state
  const [selectedVideo, setSelectedVideo] = useState(null);

  const value = {
    selectedVideo,
    setSelectedVideo,
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
}
const VideoContextConsumer = VideoContext.Consumer;

export { VideoContext, VideoContextProvider, VideoContextConsumer };
