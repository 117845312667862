/* eslint-disable no-undef */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import 'normalize.css';
// import { TransitionPortal } from 'gatsby-plugin-transition-link';
// import gsap from 'gsap';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Header from './Header';
import Footer from './Footer';

export default function Layout({ id, children, location, pageContext }) {
  const { navItems, footerItems } = useStaticQuery(
    graphql`
      query {
        navItems: sanitySingletonSite(_id: { eq: "singletonSite" }) {
          mainNavigation {
            slug {
              current
            }
            title
            titleMenu
          }
        }
        footerItems: sanitySingletonSite(_id: { eq: "singletonSite" }) {
          tel
          telDisplay
          contactLink
          facebook
          linkedin
          vimeo
          twitter
          copyNavigation {
            id
            slug {
              current
            }
            title
            titleMenu
          }
          _rawAddress(resolveReferences: { maxDepth: 10 })
          _rawAddressNantes(resolveReferences: { maxDepth: 10 })
        }
      }
    `
  );
  const slug = location.pathname.replace(/[\/\\]/g, '');
  console.log(slug);

  return (
    <div className={`app ${pageContext.slug || slug}`}>
      <GlobalStyles />
      <Typography />
      <Header id={id} navItems={navItems} />
      <div id={id} className="content">
        {children}
      </div>
      <Footer id={id} footerItems={footerItems} />
      {/* 
      <TransitionPortal level="bottom">
        <div className="curtains">
          <div className="curtain-light" />
          <div className="curtain-medium" />
          <div className="curtain-dark" />
        </div>
      </TransitionPortal>
     */}
    </div>
  );
}
