import React from 'react';
// import TransitionLink from 'gatsby-plugin-transition-link';
import styled from 'styled-components';
// import { fadeHeaderFooter } from '../utils/helpers';
import { Link } from 'gatsby';
import PortableText from './PortableText';
import { mq } from '../styles/breakpoints';

const FooterStyles = styled.div`
  .site-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.875rem;
    padding: 2em 0;

    p {
      margin: 0;
    }

    em {
      font-style: normal;
    }

    a {
      text-decoration: none;
    }

    ${mq[3]} {
      display: flex;
      flex-direction: row;

      ul {
        margin: 0 1em;
      }
    }
  }
  .footer__address {
    align-items: center;
    display: flex;
    flex-direction: column;

    a {
      border-bottom: 1px dotted var(--black);
      display: inline-block;
      padding: 10px 0 0;
    }
    .separator {
      display: none;
      padding: 0 5px;
    }

    ${mq[3]} {
      flex-direction: row;

      a {
        display: inline;
        padding: 0;
      }

      .separator {
        display: inline;
      }
    }
  }
`;

export default function Footer({ footerItems }) {
  const {
    tel,
    telDisplay,
    contactLink,
    // facebook,
    // linkedin,
    // instagram,
    // twitter,
    copyNavigation,
    _rawAddress,
    _rawAddressNantes,
  } = footerItems;

  return (
    <FooterStyles id="footer">
      <div className="container container--lg">
        <div className="site-info">
          <p>
            Corner Prod. <em>©2021</em>
          </p>

          <ul>
            {copyNavigation.map((item) => (
              <li key={item.id}>
                <Link to={`/${item.slug.current}`}>
                  {item.titleMenu ? (
                    <span className="text">{item.titleMenu}</span>
                  ) : (
                    <span className="text">{item.title}</span>
                  )}
                </Link>

                {/* <TransitionLink
                  to={`/${item.slug.current}`}
                  exit={{
                    trigger: ({ exit, node }) => fadeHeaderFooter(exit, node),
                    length: 0.5,
                  }}
                  entry={{
                    length: 0.5,
                    delay: 0.3,
                  }}
                >
                  {item.titleMenu ? (
                    <span className="text">{item.titleMenu}</span>
                  ) : (
                    <span className="text">{item.title}</span>
                  )}
                </TransitionLink> */}
              </li>
            ))}
          </ul>
          {_rawAddress && (
            <div className="footer__address">
              <PortableText blocks={_rawAddress} />{' '}
              <span className="separator"> - </span>
              <PortableText blocks={_rawAddressNantes} />
              <span className="separator"> - </span>
              {tel && <a href={`tel:${tel}`}>{telDisplay}</a>}
              <span className="separator"> - </span>
              {contactLink && <a href={contactLink}>Contactez-nous</a>}
            </div>
          )}
        </div>
      </div>
    </FooterStyles>
  );
}
