import { createGlobalStyle } from 'styled-components';
import { pxtoem } from './Mixins';
import { mq } from './breakpoints';

const GlobalStyles = createGlobalStyle`
  :root {
    --white: #ffffff;
    --black: #000000;
    --grey: #cccccc;
    --greylight: #dddddd;
    --greylighter: #eeeeee;
    --font-primary: aktiv-grotesk, Helvetica, 'Helvetica Neue', Arial, sans-serif;
    --font-headings: big-caslon-fb, serif;
    --gap: 2.5rem; /* 40px */
    --radius: 5px;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper,
  .tl-edges,
  .tl-wrapper {
    height: 100%;
  }

  body {
      background: var(--white);
      color: var(--black);
      font-family: var(--font-primary);
      font-size: 16px;
      line-height: calc(85 / 60);
      -webkit-font-smoothing: antialiased;
      overflow-x: hidden;
  }

  ul,
  ol {
      list-style: none;
      margin: 0;
      padding: 0;
  }

  img {
    max-width: 100%;
  }

  /* Layout */

  .content {
    position: relative;
    z-index: 2;

    .home & {
      align-items: center;
      display: flex;

      ${mq[2]} {
        display: block;
      }
    }
  }

  .app {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    min-height: 100vh; 
  }

  .container {
    margin: 0 auto;
    max-width: ${pxtoem(275)};
    width: 90%;

    &--xs {
      max-width: ${pxtoem(485)};
    }
    &--sm {
      max-width: ${pxtoem(695)};
    }
    &--md {
      max-width: ${pxtoem(905)};
    }
    &--lg {
      max-width: ${pxtoem(1115)};
    }
    &--xl {
      max-width: ${pxtoem(1278)};
    }
  }
  ${mq[1]} {
    .container--sm-after-sm {
      max-width: ${pxtoem(695)} !important;
    }
  }
  ${mq[2]} {
    .container--sm-after-md {
      max-width: ${pxtoem(695)} !important;
    }
    .container--xl-after-md {
      max-width: ${pxtoem(1278)} !important;
    }
  }

  /* Sections */
  .link-realisation {
    display: block;
    height: 1.4375rem;
    position: absolute;
    right: 5%;
    top: 70%;
    visibility: hidden;
    width: 0.875rem;

    ${mq[2]} {
      right: calc((100% - 1106px) / 2);
    }
  }

  .list-realisations {
    ${mq[1]} {
      padding-bottom: 9.375rem;
    }
  }

  /* Custom styles */
  .invisible {
    display: none;
  }
  .visible {
    display: block;
  }

  .video-wrapper {
    margin: 2rem 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .align-center {
    text-align: center;
  }

  /* accessibility */
  .sr-only { 
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    padding: 0;
    border: 0;
    white-space: nowrap;
  }

  .sr-only.focusable:active,
  .sr-only.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }


  .block {
    ul {
      list-style: disc;
      margin: 1em;
    }
    a {
      color: var(----black);
      display: inline-block;
      position: relative;

      &:after {
        background-image: linear-gradient(to right, var(--brown) 33%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 3px 1px;  /* Adjust values to change dot size and spacing */
        background-repeat: repeat-x;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        width: 100%;
      }
      &:hover {
        color: var(--browndark);
      }
    }
  }

  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    /* font-size: 1.05em; */
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 23px;
    height: 23px;
    border: 1px solid #aaa;
    background: #FFF;
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(145, 109, 91, 0.2);
    transition: all .275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '✓';
    position: absolute;
    top: 0.75rem;
    left: 0.3125rem;
    font-size: 1.375em;
    color: var(--black);
    line-height: 0;
    transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1) rotate(0);
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(145, 109, 91, 0.2);
  }

  /* Custom CSS Select */
  .select-css {
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23916D5B%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right .7em top 50%;
    background-size: .65em auto;
    border-radius: var(--radius);
    border: 1px solid var(--black);
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    color: var(--black);
    display: block;
    font-size: 1rem;
    /* font-family: var(--font); */
    font-weight: 500;
    line-height: 1.3;
    margin: 0;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
  }
  .select-css::-ms-expand {
    display: none;
  }
  /* Hover style */
  .select-css:hover {
    /* border-color: var(----grey-dark); */
  }
  /* Focus style */
  .select-css:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    /* color: var(--grey-dark); */
    outline: none;
  }

  /* Set options to normal weight */
  .select-css option {
    font-weight:normal;
  }

  /**
   * Mentions légales
   */

  .mentions-legales .content .block {
    font-family: var(--font-primary);
    padding-bottom: 4rem;
    text-align: left;
  }

  /**
   * PAGE TRANSITIONS
   */
  .curtain-light,
  .curtain-medium,
  .curtain-dark {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transform: translateX(100%);
    width: 100vw;
    z-index: 9999;
  }
  .curtain-light {
    background-color: var(--greylighter);
  }
  .curtain-medium {
    background-color: var(--greylight);
  }
  .curtain-dark {
    background-color: var(--grey);
  }

`;

export default GlobalStyles;
