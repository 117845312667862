import React, { useRef, useEffect, useState, forwardRef } from 'react';
import gsap from 'gsap';
import styled from 'styled-components';
// import TransitionLink from 'gatsby-plugin-transition-link';
import { Link } from 'gatsby';
import Logo from '../assets/images/logo-corner-prod.inline.svg';
import { pxtoem } from '../styles/Mixins';
import { mq } from '../styles/breakpoints';
import Burger from './Burger';

const HeaderStyles = styled.header`
  padding: ${pxtoem(22)} 0 ${pxtoem(47)};
  position: relative;
  /* visibility: hidden; */
  z-index: 3;

  .logo {
    position: relative;
    z-index: 1002;
  }

  .container {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .app.home & {
    .container {
      justify-content: center;
    }
  }

  ${mq[2]} {
    padding: ${pxtoem(72)} 0 0;

    .container {
      align-items: flex-end;
    }
    .app.home & {
      .container {
        justify-content: space-between;
      }
    }
  }
`;

const NavStyles = styled.nav`
  background: var(--black);
  box-shadow: 3px 0px 9px rgba(43, 0, 89, 0.15);
  height: 100vh;
  position: fixed;
  left: 0;
  top: -12px;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: ${({ open }) =>
    open ? 'transform 600ms ease-out' : 'transform 300ms ease-in'};
  will-change: transform;
  width: 80%;
  z-index: 1003;

  .navList {
    left: 50%;
    list-style: none;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
  }

  .menuItem {
    font-family: var(--font-headings);
    font-size: 2em;
    font-weight: normal;
    letter-spacing: 1.3px;
    line-height: 1;
    margin: 0;

    &:hover,
    a[aria-current='page'],
    a.active {
      span:after {
        transform: translateX(0);
      }
    }
  }

  a {
    color: var(--white);
    display: block;
    overflow: hidden;
    padding: 1.25rem 0;
    text-decoration: none;

    span {
      overflow: hidden;
      position: relative;

      &:after {
        background: var(--black);
        bottom: -4px;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        transform: translateX(-100%);
        transition: transform 200ms ease-out;
        width: 100%;
      }
    }

    &:hover {
      span:after {
        transform: translateX(0);
      }
    }
  }

  ${mq[2]} {
    align-items: center;
    background: none;
    box-shadow: none;
    display: flex;
    justify-content: flex-end;
    height: auto;
    left: auto;
    padding-right: 0;
    padding-top: 0;
    position: static;
    top: auto;
    transform: none;
    transition: none;
    width: 100%;

    .navList {
      display: flex;
      left: auto;
      position: relative;
      transform: none;
      top: auto;
    }

    .menuItem {
      font-family: var(--font-primary);
      font-size: 1rem;
      margin: 0 20px;

      &:last-child {
        margin: 0 0 0 20px;
      }
    }

    a {
      color: var(--black);
      padding: 6px 0;
    }

    /* .menuItem {
      &.menu-contact {
        margin-right: 0;
      }
    } */
  }
`;

// const getAnimData = (e, item) => {
//   // Returns the desired animation data based on the nav and page numbers
//   const navNum = e.target.closest('a').id;
//   const pageNum = item.id;
//   console.log(`from: ${pageNum}`);
//   console.log(`to: ${navNum}`);

//   return {
//     from: pageNum,
//     to: navNum,
//   };
// };

// const getAnim = (item, curtains, animData, animType) => {
//   // Determine the translateX value of the animation based on animData and
//   // whether the animation is an 'entry' or 'exit' for the layout
//   // let translateValue = [];
//   let opacityValue = [];

//   if (animType === 'exit') {
//     // translateValue = animData.dir === 'right' ? ['0%', '40%'] : ['0%', '-40%'];
//     opacityValue = [1, 0];
//   }
//   if (animType === 'entry') {
//     // translateValue = animData.dir === 'right' ? ['-40%', '0%'] : ['40%', '0%'];
//     opacityValue = [0, 1];
//   }

//   return gsap.timeline().to(item, { duration: 1.2, opacity: opacityValue });
//   /*
//   gsap
//     .timeline({
//       duration: 1200,
//       easing: 'easeInOutCubic',
//     })
//     .add({
//       targets: [item],
//       translateX: translateValue,
//       opacity: opacityValue,
//     });
//     */
// };

// const TRANSITION_DELAY = 1.2;
// const TRANSITION_LEN = 1.2;

const Header = ({ navItems, id }) => {
  const items = navItems.mainNavigation;
  const [open, setOpen] = useState(false);
  // const [prevScrollPos, setPrevScrollPos] = useState(0);
  // const [visible, setVisible] = useState(true);

  let windowWidth = 0;

  if (typeof window !== 'undefined') {
    windowWidth = window.innerWidth;
  }

  const [width, setWidth] = React.useState(windowWidth);
  const breakpoint = 1280;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  return (
    <HeaderStyles id="header">
      <div className="container container--lg">
        <Link to="/" id="home">
          <Logo className="logo" />
        </Link>
        {/*
         <TransitionLink
          id="home"
          to="/"
          exit={{
            length: TRANSITION_LEN,
            trigger: ({ node, e, exit, entry }) => {
              const item = node.querySelector('.content');
              const curtains = document.querySelectorAll('.curtains > div');
              console.log(item);
              const animData = getAnimData(e, item);
              // Set the state on the entry so the entering page knows the correct
              // animation data
              entry.state = { animData };
              return getAnim(item, curtains, animData, 'exit');
            },
          }}
          entry={{
            delay: TRANSITION_DELAY,
            length: TRANSITION_LEN,
            trigger: ({ node, e, exit, entry }) => {
              // Get animation data from state
              const item = node.querySelector('.content');
              const { animData } = entry.state;
              return getAnim(item, animData, 'entry');
            },
          }}
        >
          <Logo className="logo" />
        </TransitionLink>
        */}
        <NavStyles open={open}>
          <ul className="navList">
            {items.map((item, index) => (
              <li className="menuItem" key={`item-${index}`}>
                <Link
                  id={item.slug.current}
                  to={`/${item.slug.current}/`}
                  activeClassName="active"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  {item.titleMenu ? (
                    <span className="text">{item.titleMenu}</span>
                  ) : (
                    <span className="text">{item.title}</span>
                  )}
                </Link>
                {/*
                <TransitionLink
                  id={item.slug.current}
                  to={`/${item.slug.current}/`}
                  exit={{
                    length: TRANSITION_LEN,
                    trigger: ({ node, e, exit, entry }) => {
                      const content = node.querySelector('.content');
                      const animData = getAnimData(e, content);
                      // Set the state on the entry so the entering page knows the correct
                      // animation data
                      const curtains = document.querySelectorAll(
                        '.curtains > div'
                      );
                      entry.state = { animData };
                      return getAnim(content, curtains, animData, 'exit');
                    },
                  }}
                  entry={{
                    delay: TRANSITION_DELAY,
                    length: TRANSITION_LEN,
                    trigger: ({ node, e, exit, entry }) => {
                      // Get the child node so the entire Layout does not animate
                      // Get animation data from state
                      const content = node.querySelector('.content');
                      const curtains = document.querySelectorAll(
                        '.curtains > div'
                      );
                      const { animData } = entry.state;
                      return getAnim(content, curtains, animData, 'entry');
                    },
                  }}
                  activeClassName="active"
                  partiallyActive
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  {item.titleMenu ? (
                    <span className="text">{item.titleMenu}</span>
                  ) : (
                    <span className="text">{item.title}</span>
                  )}
                </TransitionLink> 
                */}
              </li>
            ))}
            <li className="menuItem">
              <a href="mailto:contact@cornerdprod.com">
                <span className="text">Contactez-nous</span>
              </a>
            </li>
          </ul>
        </NavStyles>
        <Burger open={open} setOpen={setOpen} />
      </div>
    </HeaderStyles>
  );
};

export default Header;
