import { createGlobalStyle } from 'styled-components';
import { mq } from './breakpoints';

const Typography = createGlobalStyle`

  h1,h2,h3,h4,h5,h6 {
    font-family: var(--font-titles);
    line-height: calc(50 / 55);
    margin: 0;
  }

  a {
    color: var(--brown);
    text-decoration: none;
  }

  .center {
    text-align: center;
  }

  /* Custom */
  .realisation__title {
    color: var(--white);
    font-family: var(--font-primary);
    font-size: 1.5625rem;
    font-weight: bold;
    letter-spacing: 0.32px;
    line-height: calc(30 / 25);
    text-transform: uppercase;

    a {
        color: var(--white);
        text-decoration: none;
    }

    ${mq[1]} {
      font-size: 1rem;
    }
    ${mq[2]} {
      font-size: 1.4rem;
    }
  }
  .terms {
      color: var(--white);
      font-size: 0.75rem;
  }
`;

export default Typography;
