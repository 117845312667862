import React from 'react';
import Layout from './src/components/Layout';

import { VideoContextProvider } from './src/contexts/VideoContext';

export const wrapRootElement = ({ element }) => (
  <VideoContextProvider>{element}</VideoContextProvider>
);

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
